import styled from 'styled-components';
import { Box, Container, Typography } from '@mui/material';
import { theme } from '@digitalportal-ui/core';
import { ColorsType } from '../../redux/slices/helpers/helpers.types';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Main = styled(Container)`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  flex-wrap: wrap;
  ${theme.breakpoints.down('md')} {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 40px;
  }
  ${theme.breakpoints.up('md')} {
    margin-top: 64px;
  }
`;

export const InfoBubble = styled(Typography)<{
  $chipColor: ColorsType;
}>`
  font-size: 12px;
  color: black;
  height: fit-content;
  background-color: ${({ $chipColor }) => $chipColor};
  border-radius: 10px;
  padding: 2px 8px;
  width: fit-content;

  ${theme.breakpoints.up('md')} {
    font-size: 14px;
  }
`;

export const MessageBoxStyled = styled(Box)<{ $noMarginBottom?: boolean }>`
  width: 100%;
  background: #ecf1f8;
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.15);
  border-radius: 6px;
  text-align: left;
  ${theme.breakpoints.up('xs')} {
    padding: 16px;
    margin-bottom: ${({ $noMarginBottom }) => ($noMarginBottom ? '0' : '16px')};
  }
  ${theme.breakpoints.up('md')} {
    margin-bottom: ${({ $noMarginBottom }) => ($noMarginBottom ? '0' : '32px')};
  }
`;
