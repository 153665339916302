import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '@digitalportal-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  LogoContainer,
  StyledLogo,
  PhoneContainer,
  HeaderBar,
  StyledAppBar,
  PhoneNumber,
  WorkingHours,
} from './styles';
import ProgressBar from '../ProgresBar/ProgressBar';
import { updateHelperAvailablity } from '../../../redux/slices/helpers';
import { Availability } from '../../../redux/slices/helpers/helpers.types';
import { TStore } from '../../../redux/store';

type CurrentDayType = {
  date: string;
  dayName: string;
  time: string;
};

type AvailableTimesWorkingHoursDayType = {
  start: string;
  stop: string;
};

type AvailableTimesType = {
  workingHours: {
    monday: AvailableTimesWorkingHoursDayType;
    tuesday: AvailableTimesWorkingHoursDayType;
    wednesday: AvailableTimesWorkingHoursDayType;
    thursday: AvailableTimesWorkingHoursDayType;
    friday: AvailableTimesWorkingHoursDayType;
    saturday: AvailableTimesWorkingHoursDayType;
    sunday: AvailableTimesWorkingHoursDayType;
  };
};

type HeaderProps = {
  noPhone?: boolean;
};

export const availableTimes: AvailableTimesType = {
  workingHours: {
    monday: {
      start: '09:00',
      stop: '18:00',
    },
    tuesday: {
      start: '09:00',
      stop: '18:00',
    },
    wednesday: {
      start: '09:00',
      stop: '18:00',
    },
    thursday: {
      start: '09:00',
      stop: '18:00',
    },
    friday: {
      start: '09:00',
      stop: '18:00',
    },
    saturday: {
      start: '',
      stop: '',
    },
    sunday: {
      start: '',
      stop: '',
    },
  },
};

const bankHolidays: string[] = [
  '2023-04-07T00:00:00',
  '2023-04-10T00:00:00',
  '2023-05-01T00:00:00',
  '2023-05-08T00:00:00',
  '2023-05-29T00:00:00',
  '2023-08-28T00:00:00',
  '2023-12-25T00:00:00',
  '2023-12-26T00:00:00',
  '2024-01-01T00:00:00',
  '2024-03-29T00:00:00',
  '2024-04-01T00:00:00',
  '2024-05-06T00:00:00',
  '2024-05-27T00:00:00',
  '2024-08-26T00:00:00',
  '2024-12-25T00:00:00',
  '2024-12-26T00:00:00',
  '2025-01-01T00:00:00',
  '2025-04-18T00:00:00',
  '2025-04-21T00:00:00',
  '2025-05-05T00:00:00',
  '2025-05-26T00:00:00',
  '2025-08-25T00:00:00',
  '2025-12-25T00:00:00',
  '2025-12-26T00:00:00',
];

let timeInterval: ReturnType<typeof setInterval>;

export const checkIsDateBankHoliday = (date: string) =>
  !!bankHolidays.filter((item) => dayjs(item).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')).length;

export const findNextPossibleWorkingDay = (date: string): { date: string; dayName: string } => {
  const nextDay = dayjs(date).add(1, 'day');
  const nextPossibleWorkingDay = {
    date: nextDay.format('YYYY-MM-DD'),
    dayName: nextDay.format('dddd').toLocaleLowerCase(),
  };

  if (
    nextPossibleWorkingDay.dayName === 'saturday' ||
    nextPossibleWorkingDay.dayName === 'sunday' ||
    checkIsDateBankHoliday(nextPossibleWorkingDay.date)
  ) {
    return findNextPossibleWorkingDay(nextPossibleWorkingDay.date);
  }
  return nextPossibleWorkingDay;
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export default function Header({ noPhone }: HeaderProps): React.JSX.Element {
  const dispatch = useDispatch();
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  const { loading } = useSelector((state: TStore) => state.helpersState.helpers.campaign);
  const [availability, setAvailability] = useState({
    workTimeText: '',
    workTimeColor: '',
    availabilityStatus: false,
  } as Availability);
  const setHeaderSupportWorkingTimes = () => {
    const day = dayjs();
    const currentDayDateTime: CurrentDayType = {
      date: day.format('YYYY-MM-DD'),
      dayName: day.format('dddd').toLocaleLowerCase(),
      time: day.format('HH:mm'),
    };
    const workingHours =
      availableTimes.workingHours[currentDayDateTime.dayName as keyof typeof availableTimes.workingHours];
    const nextWorkingDay = findNextPossibleWorkingDay(currentDayDateTime.date);
    const nextWorkingHours =
      availableTimes.workingHours[nextWorkingDay.dayName as keyof typeof availableTimes.workingHours];

    if (
      currentDayDateTime.dayName !== 'saturday' &&
      currentDayDateTime.dayName !== 'sunday' &&
      !checkIsDateBankHoliday(currentDayDateTime.date)
    ) {
      if (currentDayDateTime.time < workingHours.start) {
        const timeInUI = dayjs(`${currentDayDateTime.date}T${workingHours.start}`).format('ha');
        setAvailability({
          workTimeText: `We're back at ${timeInUI}`,
          workTimeColor: colors.shyTomato,
          availabilityStatus: false,
        } as Availability);
        return;
      }

      if (currentDayDateTime.time >= workingHours.start && currentDayDateTime.time < workingHours.stop) {
        const timeInUI = dayjs(`${currentDayDateTime.date}T${workingHours.stop}`).format('ha');
        setAvailability({
          workTimeText: `We're here until ${timeInUI}`,
          workTimeColor: colors.malachite,
          availabilityStatus: true,
        } as Availability);
        return;
      }

      if (currentDayDateTime.time >= workingHours.stop) {
        const timeInUI = dayjs(`${nextWorkingDay.date}T${nextWorkingHours.start}`).format('ha');
        setAvailability({
          workTimeText: `We are back on ${capitalize(nextWorkingDay.dayName)} at ${timeInUI}`,
          workTimeColor: colors.shyTomato,
          availabilityStatus: false,
        } as Availability);
        return;
      }
    }

    if (
      currentDayDateTime.dayName === 'saturday' ||
      currentDayDateTime.dayName === 'sunday' ||
      checkIsDateBankHoliday(currentDayDateTime.date)
    ) {
      const timeInUI = dayjs(`${nextWorkingDay.date}T${nextWorkingHours.start}`).format('ha');
      setAvailability({
        workTimeText: `We are back on ${capitalize(nextWorkingDay.dayName)} at ${timeInUI}`,
        workTimeColor: colors.shyTomato,
        availabilityStatus: false,
      } as Availability);
    }
  };

  useEffect(() => {
    setHeaderSupportWorkingTimes();
    clearInterval(timeInterval);
    timeInterval = setInterval(() => {
      setHeaderSupportWorkingTimes();
    }, 60000);
  }, []);

  useEffect(() => {
    dispatch(updateHelperAvailablity(availability));
  }, [availability]);

  return (
    <StyledAppBar data-testid="header" position="sticky">
      <HeaderBar data-testid="header-bar">
        <LogoContainer data-testid="header-logo-container">
          <Link data-testid="header-logo-link" to="/" aria-label="axa logo">
            <StyledLogo data-testid="header-logo-image" />
          </Link>
        </LogoContainer>
        {!noPhone && (
          <PhoneContainer>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                {loading ? (
                  <>
                    <Skeleton sx={{ width: { xs: '130px', md: '193px' }, height: { xs: '20px', md: '23px' } }} />
                    <Skeleton sx={{ width: { xs: '119px', md: '139px' }, height: { xs: '15px', md: '18px' } }} />
                  </>
                ) : (
                  <>
                    <PhoneNumber data-testid="header-support-line" to={`tel:${phoneNumber}`}>
                      Call us on {phoneNumber}
                    </PhoneNumber>
                    {availability.workTimeText && (
                      <WorkingHours data-testid="header-open-time">
                        <span style={{ color: availability.workTimeColor }}>{availability.workTimeText}</span>
                      </WorkingHours>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </PhoneContainer>
        )}
      </HeaderBar>
      <ProgressBar data-testid="header-progress-bar" />
    </StyledAppBar>
  );
}
