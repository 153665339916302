import styled from 'styled-components';
import { Alert, Snackbar } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const SnackBarMessage = styled(Alert)`
  width: 100%;
  background-color: #d32f2f;
  color: white;
  .MuiAlert-icon {
    padding: 12px 0;
  }
`;

export const SnackbarWrapper = styled(Snackbar)`
${theme.breakpoints.up('xs')} {
  width: 100%;
}
${theme.breakpoints.up('md')} {
  width: auto;
}
`;
