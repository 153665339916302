import React, { ReactElement } from 'react';
import { useNavigatorStatus } from 'react-navigator-status';
import Fallback from '../../components/templates/Fallback/Fallback';

export type OfflineHandlerProps = {
  children?: ReactElement;
};

export default function OfflineHandler({ children }: OfflineHandlerProps): React.JSX.Element | ReactElement {
  const isOnline = useNavigatorStatus();
  return !isOnline ? (
    <Fallback
      header="Whoops, looks like you&apos;re offline!"
      subHeader="There seems to be a problem with your internet connection. Please check and try again."
    />
  ) : (
    children || <div />
  );
}
