import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Text, colors } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { CalendarIcon } from '@mui/x-date-pickers';
import { TStore } from '../../../redux/store';
import { updatePastDateWarningModalInfo } from '../../../redux/slices/helpers';
import { InfoLink } from '../../atoms/Link/Link';
import { TooltipWrapper } from '../../molecules/PageIntroduction/styles';
import routes from '../../../enums/routes';
import { appInsights } from '../../../lib/utils/services/applicationInsightsService';

export default function PastDateWarningModal(): React.JSX.Element {
  const dispatch = useDispatch();
  const showPastDateModal = useSelector((state: TStore) => state.helpersState.helpers.pastDateWarningModal);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const handleClose = (): void => {
    dispatch(updatePastDateWarningModalInfo(false));
  };
  const history = useNavigate();

  useEffect(() => {
    if (showPastDateModal) {
      appInsights.trackEvent({ name: 'Cover start date in the past' });
    }
  }, [showPastDateModal]);

  return (
    <Modal variant="info" title="Before we progress" open={showPastDateModal} onClose={handleClose} dataTestid="cover-start-date-past-modal" customCloseButtonText="Okay">
      <Box>
        <Text>
          You&apos;ve gone past the start date you chose, so we&apos;ve updated your start date to today. You can change
          this if you like using the link below or just click &apos;Okay&apos;.
        </Text>

        <TooltipWrapper>
          <InfoLink
            testId="tooltip-link"
            link="Update your cover start date here"
            infoLinkIcon={<CalendarIcon sx={{ color: colors.oceanBlueDark, marginRight: '5px' }} />}
            onClickHandler={() => {
                history(routes.coverStartDate, navigateOptions);
                handleClose();
              }}
          />
        </TooltipWrapper>
      </Box>
    </Modal>
  );
}
