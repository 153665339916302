import { v4 as uuidv4 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductOptionsType } from '../helpers/helpers.types';
import { Member, Quote, QuoteCampaign, MajorHealthQuestions, AddressType } from './quote.types';

const today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0');
const yyyy = today.getFullYear();
const formattedDate = `${yyyy}-${mm}-${dd}`;

type InitialState = {
  quote: Quote;
  quoteSnapshot?: Quote;
  leadMemberSnapshot?: Member;
};

export const initialMemberState = {
  id: uuidv4(),
  policyHolder: false,
  onCover: false,
  businessSource: 'Virgin',
  underwriting: 'Mori',
  title: '',
  firstname: '',
  lastname: '',
  preferredName: '',
  dob: {
    day: '',
    month: '',
    year: '',
  },
  fullAddress: '',
  postcode: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  email: '',
  phone: '',
  countrycode: '+44',
  entitlements: [],
  conditions: null,
  annualPremium: 0,
  monthlyPremium: 0,
  price: 0,
  excess: '',
  consultation: '',
  ncdProtection: 'No',
  excessOptions: [],
  consultationOptions: [],
  leadId: '',
  expirationDate: '',
  firstPaymentDate: '',
  addressSameAsPolicyHolder: false,
  majorHealthQuestions: {},
  frictionEnabled: false,
  ipidId: '',
  excessConfig: {} as ProductOptionsType,
  consultationConfig: {} as ProductOptionsType,
};

export const initialState: InitialState = {
  quote: {
    quoteNumber: '',
    quoteKey: '',
    quoteNumberAnnual: '',
    quoteKeyAnnual: '',
    coverStartDate: formattedDate,
    expirationDate: '',
    annualPremium: 0,
    monthlyPremium: 0,
    annualTotalContractPrice: 0,
    monthlyTotalContractPrice: 0,
    annualTaxPremium: 0,
    monthlyTaxPremium: 0,
    hospitalNetwork: '',
    opportunityId: '',
    paymentFrequency: 'Monthly',
    hospitalNetworkOptions: [],
    hospitalNetworkConfig: {} as ProductOptionsType,
    quoteStatus: 'Open',
    members: [{ ...initialMemberState, policyHolder: true }],
    campaign: {} as QuoteCampaign,
  },
};

const quoteSlice = createSlice({
  name: 'quoteSlice',
  initialState,
  reducers: {
    updateQuote: (state: typeof initialState, { payload }: PayloadAction<Partial<Quote>>) => {
      state.quote = { ...state.quote, ...payload };
    },
    updateQuoteSnapshot: (state: typeof initialState, { payload }: PayloadAction<Partial<Quote>>) => {
      state.quoteSnapshot = { ...state.quote, ...payload };
    },
    updateLeadMemberSnapshot: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        values: Partial<Member>;
      }>,
    ) => {
      state.leadMemberSnapshot = {
        ...state.quote.members[payload.userEditIndex],
        ...payload.values,
      };
    },
    updateQuoteCoverStartDate: (state: typeof initialState, { payload }: PayloadAction<{ coverStartDate: string }>) => {
      state.quote.coverStartDate = payload.coverStartDate;
    },
    updateQuoteHospitalNetwork: (
      state: typeof initialState,
      { payload }: PayloadAction<{ hospitalNetwork: string }>,
    ) => {
      state.quote.hospitalNetwork = payload.hospitalNetwork;
    },
    updateQuoteHospitalNetworkOptions: (
      state: typeof initialState,
      { payload }: PayloadAction<{ hospitalNetworkConfig: ProductOptionsType }>,
    ) => {
      state.quote.hospitalNetworkConfig = payload.hospitalNetworkConfig;
    },
    updateQuoteMonthlyPremiums: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        price: number;
        tax: number;
        totalContractPrice: number;
      }>,
    ) => {
      state.quote.monthlyPremium = payload.price;
      state.quote.monthlyTaxPremium = payload.tax;
      state.quote.monthlyTotalContractPrice = payload.totalContractPrice;
    },
    updateQuoteAnnualPremiums: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        price: number;
        tax: number;
        totalContractPrice: number;
      }>,
    ) => {
      state.quote.annualPremium = payload.price;
      state.quote.annualTaxPremium = payload.tax;
      state.quote.annualTotalContractPrice = payload.totalContractPrice;
    },
    addMember: (state: typeof initialState, { payload }: PayloadAction<Member>) => {
      state.quote.members = [...state.quote.members, payload];
    },

    updateMember: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        values: Partial<Member>;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex] = {
        ...state.quote.members[payload.userEditIndex],
        ...payload.values,
      };
    },

    updateMemberMajorHealthQuestions: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        majorHealthQuestions: MajorHealthQuestions;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex].majorHealthQuestions = {
        ...state.quote.members[payload.userEditIndex].majorHealthQuestions,
        ...payload.majorHealthQuestions,
      };
    },

    updateMemberExcess: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        excess: string;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex].excess = payload.excess;
    },

    updateMemberConsultation: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        consultation: string;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex].consultation = payload.consultation;
    },

    updateMemberNCD: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        ncdProtection: string;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex].ncdProtection = payload.ncdProtection;
    },

    removeMember: (state, { payload }: PayloadAction<number>) => {
      state.quote.members = [...state.quote.members.slice(0, payload), ...state.quote.members.slice(payload + 1)];
    },

    updateAddress: (state: typeof initialState, { payload }: PayloadAction<AddressType>) => {
      state.quote.members[0] = { ...state.quote.members[0], ...payload };
    },

    updateIpidForMember: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        ipidId: string;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex].ipidId = payload.ipidId;
    },

    updateMemberEmail: (
      state: typeof initialState,
      {
        payload,
      }: PayloadAction<{
        userEditIndex: number;
        email: string;
      }>,
    ) => {
      state.quote.members[payload.userEditIndex].email = payload.email;
    },
    resetQuote: (state: typeof initialState) => {
      state.quote = initialState.quote;
      state.quoteSnapshot = initialState.quote;
      state.leadMemberSnapshot = initialMemberState;
    },
  },
});

export const quoteReducer = quoteSlice.reducer;
export const {
  updateQuote,
  updateQuoteSnapshot,
  updateQuoteCoverStartDate,
  updateQuoteHospitalNetwork,
  updateQuoteHospitalNetworkOptions,
  updateQuoteMonthlyPremiums,
  updateQuoteAnnualPremiums,
  addMember,
  updateMember,
  updateLeadMemberSnapshot,
  updateMemberMajorHealthQuestions,
  updateMemberExcess,
  updateMemberConsultation,
  updateMemberNCD,
  removeMember,
  updateAddress,
  updateIpidForMember,
  updateMemberEmail,
  resetQuote,
} = quoteSlice.actions;
