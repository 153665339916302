import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import { ProgressLinear } from './styles';
import { GetPageTitleFromRoute } from '../../../lib/utils/pageTitleHandler';

const routesArray = Object.values(Routes);
const progressStep = 100 / routesArray.length;

export default function ProgressBar(): React.JSX.Element {
  const { pathname } = useLocation();
  const currentPosition = routesArray.indexOf(pathname as Routes) + 1;
  const progress = currentPosition * progressStep;
  const progressBarState = useSelector((state: TStore) =>
    typeof state.helpersState.helpers.isProgressShown === 'undefined' ||
    state.helpersState.helpers.isProgressShown === true
      ? true
      : state.helpersState.helpers.isProgressShown,
  );

  useEffect(() => {
    if (!pathname) return;
    const pageTitle = GetPageTitleFromRoute(pathname);
    document.title = pageTitle;
    const tagManagerArgs = {
      dataLayer: {
        event: 'virtual_pageview',
        page_name: pageTitle,
        page_path: pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [pathname]);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{progressBarState ? <ProgressLinear variant="determinate" value={progress} aria-label="Progress bar" /> : null}</>
  );
}
