import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { modalTabIndexFix } from '../lib/utils/modalTabIndexFix';
import { TStore } from '../redux/store';

export function useSetModalPageView() {
    const modalInfo = useSelector((state: TStore) => state.helpersState.helpers.modal);

    useEffect(() => {
        if (modalInfo.isModalOpen) {
            modalTabIndexFix(-1);
            if (modalInfo.modalTitle !== '') {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'modal_view',
                        modal_title: modalInfo.modalTitle,
                    },
                });
            }
        } else {
            modalTabIndexFix(0);
        }
    }, [modalInfo]);
}
