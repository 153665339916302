import { combineReducers } from 'redux';
import { quoteReducer } from '../slices/quote';
import { helpersReducer } from '../slices/helpers';
import { policyReducer } from '../slices/policy/policy.slice';

const reducer = combineReducers({
  quoteState: quoteReducer,
  helpersState: helpersReducer,
  policyState: policyReducer,
  // another reducers (if we have)
});

export default reducer;
