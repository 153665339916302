import styled from 'styled-components';
import { Box } from '@mui/material';
import { H1, theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  padding-bottom: 150px;
`;

export const Heading = styled(H1)`
  margin-bottom: 32px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 64px;
  }
`;

export const ContentWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 540px;
  }
`;
