import styled from 'styled-components';
import { Typography, Box } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Wrapper = styled.div`
  margin-bottom: 32px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 64px;
  }

  ${theme.breakpoints.up('xs')} {
    word-break: break-word;
  }
`;

export const Title = styled(Typography)<{ $subtitleExists: boolean }>`
  color: ${colors.darkGrey};
  word-break: break-word;
  margin-bottom: ${({ $subtitleExists }) => ($subtitleExists ? '16px' : '0')};

  ${theme.breakpoints.up('md')} {
    margin-bottom: ${({ $subtitleExists }) => ($subtitleExists ? '32px' : '0')};
  }
`;

export const Subtitle = styled(Typography)`
  color: ${colors.darkGrey};
  line-height: 22.63px;

  ${theme.breakpoints.up('md')} {
    line-height: 25px;
  }
`;

export const InfoOutlinedIconStyled = styled(InfoOutlinedIcon)`
  margin-right: 5px;
  color: ${colors.oceanBlueDark};
  ${theme.breakpoints.up('md')} {
    margin-right: 10px;
  }
`;

export const TooltipWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.up('xs')} {
    margin: 16px 0 15px 0;
  }
  ${theme.breakpoints.up('md')} {
    margin: 32px 0 30px 0;
  }
`;
