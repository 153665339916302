enum Routes {
  triage = '/',
  insurer = '/insurer',
  switchLapse = '/switch-lapse',
  switchHandoff = '/switch-handoff',
  beforeYouStart = '/before-you-start',
  coverStartDate = '/cover-start-date',
  yourName = '/your-name',
  dateOfBirth = '/date-of-birth',
  address = '/address',
  contactDetails = '/contact-details',
  additionalMembers = '/additional-members',
  offlineRedirect = '/callback',
  majorHealthQuestions = '/major-health-questions',
  entitlements = '/entitlements',
  guidedSelection = '/guided-selection',
  excess = '/excess',
  consultations = '/consultations',
  hospitals = '/hospitals',
  ncd = '/ncd',
  quoteSummary = '/quote-summary',
  paymentDetails = '/payment-details',
  checkoutPolicyDocs = '/checkout-policy-docs',
  cardDetails = '/card-details',
  directDebit = '/direct-debit',
  directDebitInstruction = '/direct-debit-instruction',
  retrieveQuote = '/retrieve-quote/:quoteKey',
  unhappyPath = '/error',
  paymentSuccess = '/payment-success',
}

export default Routes;
