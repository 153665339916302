import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, theme } from '@digitalportal-ui/core';
import { Link as MuiLink, Box } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { InfoOutlinedIconStyled } from '../../molecules/PageIntroduction/styles';

type InfoLinkProps = {
  link: string;
  testId: string;
  infoLinkIcon?: ReactNode;
  hideInfoLinkIcon?: boolean;
  onClickHandler: () => void;
};

export const StyledLink = styled(Link)<{
  $disabled?: boolean;
  $margin?: string;
  $marginTop?: string;
  $display?: string;
}>`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  line-height: 20px;
  color: ${(props) => (props.$disabled ? colors.mediumGrey : colors.oceanBlueDark)};
  margin: ${(props) => props.$margin || 0};
  display: ${(props) => props.$display || 'block'};
  cursor: pointer;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'auto')};

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    line-height: 18px;
    margin-top: ${(props) => props.$marginTop || '16px'};
  }
`;

export const ExternalStyledLink = styled(MuiLink)<{
  $disabled?: boolean;
  $margin?: string;
  $marginTop?: string;
  $inline?: boolean;
}>`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  line-height: 20px;
  color: ${(props) => (props.$disabled ? colors.mediumGrey : colors.oceanBlueDark)};
  margin: ${(props) => props.$margin || 0};
  display: ${(props) => (props.$inline ? 'inline' : 'block')};
  cursor: pointer;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'auto')};

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    margin-top: ${(props) => props.$marginTop || '16px'};
  }
`;

export function InfoLink({
  link,
  testId,
  infoLinkIcon,
  hideInfoLinkIcon,
  onClickHandler,
}: InfoLinkProps): React.JSX.Element {
  const eventClickHandler = useCallback(
    (event: { preventDefault: () => void; stopPropagation: () => void; key?: string }) => {
      if (!event.key || event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        onClickHandler();
      }
    },
    [onClickHandler],
  );

  const LinkIcon = infoLinkIcon || <InfoOutlinedIconStyled />;

  return (
    <Box
      data-testid={`${testId}-wrapper`}
      style={{ cursor: 'pointer', textAlign: 'left' }}
      display="flex"
      alignItems="center"
      onClick={eventClickHandler}
      onKeyDown={eventClickHandler}
    >
      {hideInfoLinkIcon ? null : LinkIcon}
      <StyledLink to=" " $marginTop="0" data-testid={testId} onClick={eventClickHandler}>
        {link}
      </StyledLink>
    </Box>
  );
}
