import styled from 'styled-components';
import { LinearProgress } from '@mui/material';

export const ProgressLinear = styled(LinearProgress)`
  background-color: #e5e5e5;
  height: 8px;
  .MuiLinearProgress-barColorPrimary {
    background-color: #00adc6;
  }
`;
