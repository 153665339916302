import styled from 'styled-components';
import { Card, Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const Wrapper = styled(Card)`
  text-align: left;
  height: fit-content;
  width: 100%;
  border-radius: 6px;
  padding: 8px 0;
  border: none;
  box-shadow: 0px 2px 4px 0px #0000000f;

  ${theme.breakpoints.up('md')} {
    padding: 16px 0;
  }
  ${theme.breakpoints.up('lg')} {
    width: 290px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 350px;
  }
`;

export const FieldBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  * {
    width: 202px;
  }
  ${theme.breakpoints.up('md')} {
    margin: 8px 0;
  }
`;

export const SpacedGrid = styled(Box)`
  padding: 4px 16px;
  display: flex;
  flex-direction: column;
  ${theme.breakpoints.up('md')} {
    padding: 8px 32px;
  }
`;
