import styled from 'styled-components';
import { Box, Link } from '@mui/material';
import Lottie from 'lottie-react';
import { theme, colors, Text } from '@digitalportal-ui/core';

export const FooterContainer = styled.footer`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiGrid-container {
    height: 100%;
  }
  ${theme.breakpoints.up('xs')} {
    height: 76px;
    padding: 0 16px;
  }
  ${theme.breakpoints.up('md')} {
    height: 114px;
    padding: 0 32px;
  }
`;

export const FooterContainerStaticLinks = styled(FooterContainer)`
  border-top: 1px solid ${colors.silver};
  position: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.up('xs')} {
    height: initial;
    padding: 8px 8px 0;
  }
  ${theme.breakpoints.up('md')} {
    height: initial;
    padding: 16px 16px 0;
  }
`;

export const StaticLinksContainer = styled(Box)`
  width: 320px;
  ${theme.breakpoints.up('md')} {
    margin: auto;
    width: 768px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 1200px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1440px;
  }
`;

export const StaticLinkWrapper = styled(Box)`
  margin-right: 8px;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-right: 16px;
    margin-bottom: 16px;
  }
`;

export const StaticLink = styled(Link)`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: ${colors.grey};
  cursor: pointer;

  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StaticText = styled(Text)`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grey};
  display: contents;

  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FooterText = styled(Text)`
  margin-bottom: 8px;
  width:  288px;
  height: 128px;
  text-align: center;
  font-size: 13px;
  line-height: 16px;

  ${theme.breakpoints.up('md')} {
    width: 736px;
    height: 48px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1022px;
    height: 32px;
  }
`;

export const FooterTextLink = styled(Link)`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  color: ${colors.oceanBlue300};
  cursor: pointer;
`;
export const FooterWrapper = styled(Box)`
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;
export const AnimationWrapper = styled(Lottie)`
  margin-right: 8px;
  width: 49px;
  height: 49px;

  ${theme.breakpoints.up('md')} {
    margin-right: 16px;
  }
`;
