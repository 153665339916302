import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommunicationNeeds,
  ContactPreferences,
  MarketingConsent,
  PaymentMethodDetailsType,
  Policy,
  PolicyFormsPart,
} from './policy.types';

type InitialState = {
  policy: Policy;
};

export const initialState: InitialState = {
  policy: {
    policyPayed: false,
    selectedPaymentMethod: 'Card' as 'Card' | 'Direct Debit',
    billingAddress: null,
    paymentFrequency: '',
    paymentMethodDetails: {
      card: {
        transactionReference: '',
      },
      directDebit: {
        accountName: 'testValue',
        accountNumber: 'testValue',
        sortCode: '22-22-22',
        base64Hash: '',
      },
    },
    quoteKey: 'testValue',
    quoteNumber: 'testValue',
    regularPaymentDay: '',
    storingCardConsent: { isChecked: false },
    thirdPartyPayer: false,
    policyNumber: '',
    marketingConsents: {
      post: false,
      email: false,
      textMessage: false,
      phoneCall: false,
    },
    communicationPreference: 0,
    communicationNeeds: {
      documentsInBraille: false,
      documentsInLargePrint: false,
      audio: false,
      relayUK: false,
      signLanguageService: false,
    },
    contactPreferences: {
      post: false,
      email: false,
      phoneCall: false,
    },
  },
};

const policySlice = createSlice({
  name: 'policySlice',
  initialState,
  reducers: {
    updatePolicyPayed: (state: typeof initialState, { payload }: PayloadAction<Partial<boolean>>) => {
      state.policy = { ...state.policy, ...{ policyPayed: payload } };
    },
    updateViaPost: (state: typeof initialState, { payload }: PayloadAction<Partial<boolean>>) => {
      state.policy = { ...state.policy, ...{ updatesViaPost: payload } };
    },
    updateFormsPart: (state: typeof initialState, { payload }: PayloadAction<Partial<PolicyFormsPart>>) => {
      state.policy = { ...state.policy, ...payload };
    },
    updatePaymentMethodType: (state: typeof initialState, { payload }: PayloadAction<'Card' | 'Direct Debit'>) => {
      state.policy = { ...state.policy, selectedPaymentMethod: payload };
    },
    updateFrequency: (state: typeof initialState, { payload }: PayloadAction<boolean>) => {
      state.policy = {
        ...state.policy,
        paymentFrequency: payload ? 'Monthly' : 'Annual',
      };
    },
    updatePaymentMethodDetails: (state: typeof initialState, { payload }: PayloadAction<PaymentMethodDetailsType>) => {
      state.policy = { ...state.policy, paymentMethodDetails: payload };
    },
    updatePolicyNumber: (state: typeof initialState, { payload }: PayloadAction<string>) => {
      state.policy = {
        ...state.policy,
        policyNumber: payload,
      };
    },
    updateMarketingConsents: (state: typeof initialState, { payload }: PayloadAction<MarketingConsent>) => {
      state.policy.marketingConsents = payload;
    },
    updateContactPreferences: (state: typeof initialState, { payload }: PayloadAction<ContactPreferences>) => {
      state.policy.contactPreferences = payload;
    },
    updateCommunicationNeeds: (state: typeof initialState, { payload }: PayloadAction<CommunicationNeeds>) => {
      state.policy.communicationNeeds = payload;
    },
    updateCommunicationPreference: (state: typeof initialState, { payload }: PayloadAction<number>) => {
      state.policy.communicationPreference = payload;
    },
    resetPolicy: (state: typeof initialState) => {
      state.policy = initialState.policy;
    },
  },
});

export const policyReducer = policySlice.reducer;
export const {
  updatePolicyPayed,
  updateViaPost,
  updateFormsPart,
  updateFrequency,
  updatePaymentMethodDetails,
  updatePaymentMethodType,
  updatePolicyNumber,
  updateMarketingConsents,
  updateContactPreferences,
  updateCommunicationNeeds,
  updateCommunicationPreference,
  resetPolicy,
} = policySlice.actions;
