import Routes from '../../enums/routes';

export function GetPageTitleFromRoute(route: string) {
  switch (route) {
    case Routes.triage:
      return 'Your insurance history | AXA Health Plan | AXA Health';
    case Routes.insurer:
      return 'Your previous insurer | AXA Health Plan | AXA Health';
    case Routes.switchLapse:
      return 'Your policy expiration | AXA Health Plan | AXA Health';
    case Routes.switchHandoff:
      return 'Please call us | AXA Health Plan | AXA Health';
    case Routes.beforeYouStart:
      return 'Before you start | AXA Health Plan | AXA Health';
    case Routes.coverStartDate:
      return 'Your cover start date | AXA Health Plan | AXA Health';
    case Routes.yourName:
      return 'Your personal details | AXA Health Plan | AXA Health';
    case Routes.dateOfBirth:
      return 'Your date of birth | AXA Health Plan | AXA Health';
    case Routes.address:
      return 'Your address | AXA Health Plan | AXA Health';
    case Routes.contactDetails:
      return 'Your contact details | AXA Health Plan | AXA Health';
    case Routes.additionalMembers:
      return 'Your additional members | AXA Health Plan | AXA Health';
    case Routes.majorHealthQuestions:
      return 'About your health | AXA Health Plan | AXA Health';
    case Routes.entitlements:
      return 'Choose your options | AXA Health Plan | AXA Health';
    case Routes.guidedSelection:
      return 'Guided selection | AXA Health Plan | AXA Health';
    case Routes.excess:
      return 'Your excess | AXA Health Plan | AXA Health';
    case Routes.consultations:
      return 'Your specialist consultations | AXA Health Plan | AXA Health';
    case Routes.hospitals:
      return 'Your hospital network | AXA Health Plan | AXA Health';
    case Routes.ncd:
      return 'Protecting your no claims discount | AXA Health Plan | AXA Health';
    case Routes.quoteSummary:
      return 'Your quote summary | AXA Health Plan | AXA Health';
    case Routes.paymentDetails:
      return 'Your payment method | AXA Health Plan | AXA Health';
    case Routes.checkoutPolicyDocs:
      return 'Your preferences | AXA Health Plan | AXA Health';
    case Routes.cardDetails:
      return 'Confirm payment | AXA Health Plan | AXA Health';
    case Routes.directDebit:
      return 'Your Direct Debit details | AXA Health Plan | AXA Health';
    case Routes.directDebitInstruction:
      return 'Your Direct Debit confirmation | AXA Health Plan | AXA Health';
    case Routes.unhappyPath:
      return "There's a problem | AXA Health Plan | AXA Health";
    case Routes.paymentSuccess:
      return 'Thank you | AXA Health Plan | AXA Health';
    case Routes.offlineRedirect:
      return 'Thank you for your information | AXA Health Plan | AXA Health';
    default:
      if (route.includes(Routes.retrieveQuote.substring(0, Routes.retrieveQuote.indexOf('/', 1)))) {
        return 'Get back to your quote | AXA Health Plan | AXA Health';
      }
       return '';
  }
}

export function UpdatePageTitle(title: string) {
  document.title = `${title} | AXA Health Plan | AXA Health`;
}
