import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const FallbackWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(100vh - 57px);
  background-color: ${colors.dune100};
  padding-top: 32px;
  align-content: center;
  ${theme.breakpoints.up('md')} {
    min-height: calc(100vh - 71px);
    justify-content: center;
    padding-top: 0;
  }
`;

export const ContainerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  ${theme.breakpoints.up('md')} {
    gap: 70px;
  }
  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
    gap: 34px;
  }
`;

export const FallbackImageWrapper = styled(Box)`
  svg {
    width: 100%;
    height: 227px;
  }
  ${theme.breakpoints.up('md')} {
    svg {
      height: 445px;
    }
  }
  ${theme.breakpoints.up('lg')} {
    svg {
      height: 407px;
    }
  }
  ${theme.breakpoints.up('xl')} {
    svg {
      height: 496px;
    }
  }
`;

export const Wrapper = styled(Box)`
  text-align: start;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
