import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EntitlementProductType,
  FAQ,
  HelpersState,
  MajorHealthQuestionType,
  ProductRules,
  MajorHealthQuestions,
  ModalInfo,
  Availability,
  Campaign,
  ApiError,
  Providers,
} from './helpers.types';
import GuidedSelectionOptions from '../../../enums/guidedSelection';

const initialState: HelpersState = {
  helpers: {
    initialEntitlements: [],
    isProgressShown: true,
    modal: {
      isModalOpen: false,
      modalTitle: '',
    },
    pastDateWarningModal: false,
    faqs: [],
    triage: 0,
    campaign: {
      success: false,
      marketingRef: '',
      marketingSource: '',
      termsConditions: '',
      incentive: '',
      loading: false,
    },
    insurer: 0,
    switchLapse: 0,
    who: 0,
    retrieveQuoteModal: false,
    majorHealthQuestions: {
      nextButtonClicked: false,
      questions: [],
      answers: [],
    },
    productRules: {},
    apiError: {
      hasError: false,
      message: '',
    },
    navigateOptions: {
      state: {
        usedRouter: true,
      },
    },
    providers: {
      networks: [],
      postcodeLat: 0,
      postcodeLng: 0,
    },
    availability: {
      workTimeText: '',
      availabilityStatus: false,
      workTimeColor: '',
    },
    quoteRetrieved: false,
    salesPhoneNumber: '0800 169 7593',
    guidedSelection: GuidedSelectionOptions.guided,
  },
};

const helpersSlice = createSlice({
  name: 'helpersSlice',
  initialState,
  reducers: {
    updateHelperTriage: (
      state,
      {
        payload,
      }: PayloadAction<{
        triage: number;
      }>,
    ) => {
      state.helpers.triage = payload.triage;
    },
    updateCampaign: (
      state,
      {
        payload,
      }: PayloadAction<{
        campaign: Partial<Campaign>;
      }>,
    ) => {
      state.helpers.campaign = {
        ...state.helpers.campaign,
        ...payload.campaign,
      };
    },
    updateHelperInsurer: (
      state,
      {
        payload,
      }: PayloadAction<{
        insurer: number;
      }>,
    ) => {
      state.helpers.insurer = payload.insurer;
    },
    updateHelperSwitchLapse: (
      state,
      {
        payload,
      }: PayloadAction<{
        switchLapse: number;
      }>,
    ) => {
      state.helpers.switchLapse = payload.switchLapse;
    },
    updateHelperWho: (
      state,
      {
        payload,
      }: PayloadAction<{
        who: number;
      }>,
    ) => {
      state.helpers.who = payload.who;
    },
    updateHelperEntitlements: (
      state,
      {
        payload,
      }: PayloadAction<{
        entitlements: EntitlementProductType[];
        mhQs: MajorHealthQuestionType[];
        productRules: ProductRules;
      }>,
    ) => {
      state.helpers.initialEntitlements = payload.entitlements;
      state.helpers.majorHealthQuestions.questions = payload.mhQs;
      state.helpers.productRules = payload.productRules;
    },
    updateHelperIsProgressShown: (
      state,
      {
        payload,
      }: PayloadAction<{
        isProgressShown: boolean;
      }>,
    ) => {
      state.helpers.isProgressShown = payload.isProgressShown;
    },
    updateModalInfo: (state, { payload }: PayloadAction<ModalInfo>) => {
      state.helpers.modal = payload;
    },
    updatePastDateWarningModalInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.helpers.pastDateWarningModal = payload;
    },

    updateApiError: (
      state,
      {
        payload,
      }: PayloadAction<{
        apiError: ApiError;
      }>,
    ) => {
      state.helpers.apiError = payload.apiError;
    },
    updateHelperFAQ: (
      state,
      {
        payload,
      }: PayloadAction<{
        faqs: FAQ[];
      }>,
    ) => {
      state.helpers.faqs = payload.faqs;
    },
    updateHelperMajorHealthQuestions: (state, action: PayloadAction<Partial<MajorHealthQuestions>>) => {
      state.helpers.majorHealthQuestions = { ...state.helpers.majorHealthQuestions, ...action.payload };
    },
    updateHelperProviders: (
      state,
      {
        payload,
      }: PayloadAction<{
        providers: Providers;
      }>,
    ) => {
      state.helpers.providers = payload.providers;
    },
    updateHelperAvailablity: (state, { payload }: PayloadAction<Availability>) => {
      state.helpers.availability = payload;
    },
    resetHelpers: (state: typeof initialState) => {
      state.helpers = initialState.helpers;
    },
    updateQuoteRetrieved: (state, { payload }: PayloadAction<boolean>) => {
      state.helpers.quoteRetrieved = payload;
    },
    updateSalesPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.helpers.salesPhoneNumber = payload;
    },
    updateHelperGuidedSelection: (state, action: PayloadAction<GuidedSelectionOptions>) => {
      state.helpers.guidedSelection = action.payload;
    },
  },
});

export const helpersReducer = helpersSlice.reducer;
export const {
  updateHelperMajorHealthQuestions,
  updateHelperIsProgressShown,
  updateModalInfo,
  updatePastDateWarningModalInfo,
  updateApiError,
  updateHelperEntitlements,
  updateHelperTriage,
  updateCampaign,
  updateHelperInsurer,
  updateHelperSwitchLapse,
  updateHelperWho,
  updateHelperFAQ,
  updateHelperProviders,
  updateHelperAvailablity,
  resetHelpers,
  updateQuoteRetrieved,
  updateSalesPhoneNumber,
  updateHelperGuidedSelection,
} = helpersSlice.actions;
