import axios from 'axios';
import configData from '../../../config/config.json';
import { BeginPayment, Policy } from '../../../redux/slices/policy/policy.types';

const headers = {
  'Content-Type': 'application/json',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function quoteToPolicy(data: Policy): Promise<any> {
  const response = await axios.post(configData.QUOTETOPOLICY_URL, data, {
    headers,
  });
  return response.data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function beginPayment(data: BeginPayment): Promise<any> {
  const response = await axios.post(configData.BEGINPAYMENT_URL, data, {
    headers,
  });
  return response.data;
}
