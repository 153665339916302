import styled, { keyframes } from 'styled-components';
import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import { theme, colors } from '@digitalportal-ui/core';

export const Wrapper = styled(Box)`
  height: 100%;
  top: 0;
  position: fixed;
  margin-left: 16px;
  margin-right: 16px;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 32px;
  }
`;

export const AnimationWrapper = styled(Lottie)`
  width: 100%;
  height: 146px;
  margin-bottom: 16px;
  ${theme.breakpoints.up('md')} {
   height: 220px;
   margin-bottom: 32px;
  }
  position: relative;
`;

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const GradientBox = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: conic-gradient(
    from 90deg at 50% 50%,
    ${colors.oceanBlueDark} 0deg,
    rgba(58, 94, 148, 0) 0.04deg,
    ${colors.oceanBlueDark} 360deg
  );
  animation: ${rotationAnimation} 1s linear infinite;
`;

export const Circle = styled(Box)<{ $background?: string }>`
  position: absolute;
  background: ${({ $background }) => $background || colors.wildSand};
  top: 4px;
  left: 4px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
`;
