import styled from 'styled-components';
import { Typography, Box } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';

export const PriceWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Price = styled(Typography)`
  font-weight: bold;
  font-style: normal;
  color: ${colors.darkGrey};
  display: inline;
  line-height: 85%;
  ${theme.breakpoints.up('xs')} {
    font-size: 24px;
  }
  ${theme.breakpoints.up('md')} {
    font-size: 44px;
  }
`;

export const PerMonthLong = styled(Typography)`
  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    font-style: normal;
    line-height: 85%;
    display: inline;
    font-weight: 600;
    color: ${colors.grey};
  }

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    font-style: normal;
    line-height: 85%;
    display: inline;
    font-weight: 600;
    color: ${colors.grey};
  }
`;
