import { useEffect } from 'react';
import { emitCustomEvent } from '../lib/utils/eventHandler';

export function useSetNavigationControls() {
    // Preventing Iphones from auto zooming on fields
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const handleBackNavigation = () => emitCustomEvent('pageNavigationBack');

    useEffect(() => {
        if (isIOS) {
            document
              ?.querySelector('[name=viewport]')
              ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
        }

        // Intercepting the browser back button and making it use our router navigation handler
        window.addEventListener('popstate', handleBackNavigation);
        return () => window.removeEventListener('popstate', handleBackNavigation);
      });
}
