import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../enums/routes';

export function usePageRouteDefaults(blockManualAddressBarRouting = true) {
  const history = useNavigate();
  const { pathname } = useLocation();
  const locationState = useLocation().state as { usedRouter: boolean };

  useEffect(() => {
    if (blockManualAddressBarRouting) {
      const retrieveIndex = routes.retrieveQuote.lastIndexOf('/');
      if (
        (locationState === null || !locationState.usedRouter) &&
        !pathname.includes(routes.retrieveQuote.slice(0, retrieveIndex)) &&
        !pathname.includes(routes.paymentSuccess) &&
        !pathname.includes(routes.paymentDetails)
      ) {
        history(routes.triage);
      }
    }
    window.scrollTo(0, 0);
  }, [pathname, locationState, history]);
}
