import { useSelector } from 'react-redux';
import { Price, PerMonthLong, PriceWrapper } from './styles';
import { TStore } from '../../../redux/store';

type PriceDisplayProps = {
  price?: number;
};

export default function PriceDisplay({ price }: PriceDisplayProps): React.JSX.Element {
  const monthlyPrice: number = useSelector((state: TStore) => state.quoteState.quote.monthlyPremium);

  return (
    <PriceWrapper>
      <Price>£{Number(price !== undefined ? price : monthlyPrice).toFixed(2)}</Price>
      <PerMonthLong> per month</PerMonthLong>
    </PriceWrapper>
  );
}
