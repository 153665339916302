import { theme } from '@digitalportal-ui/core';
import { Box, Card } from '@mui/material';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  padding: 0;
  margin-top: 16px;
  ${theme.breakpoints.up('md')} {
    width: 100%;
    margin-top: 32px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
    margin-top: 0;
    margin-right: 30px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const MainWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0;
  flex-direction: column-reverse;
  margin: 0 16px 100px;
  align-items: center;
  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    align-items: start;
  }
`;

export const MainContentWrapper = styled(Box)`
  margin-top: 16px;

  ${theme.breakpoints.up('md')} {
    margin-top: 32px;
  }

  ${theme.breakpoints.up('lg')} {
    margin-top: 0;
    margin-right: 32px;
  }
`;

export const Wrapper = styled(Card)`
  text-align: left;
  padding: 16px;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 2px 4px 0px #0000000f;
  &:first-child {
    margin-bottom: 20px;
  }
  position: relative;
  width: 100%;
  margin-bottom: 80px;
  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const SummaryWrapper = styled(Box)`
  ${theme.breakpoints.up('md')} {
    width: 690px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 290px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 350px;
  }
`;
