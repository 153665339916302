export const modalTabIndexFix = (value: number) => {
  const selectorList = 'button, [role="button"], a, input, textarea, div[tabIndex]';
  const foundDOMElements = document.querySelectorAll(selectorList);

  foundDOMElements.forEach((element) => {
    const domElement = element as HTMLElement;
    domElement.tabIndex = value;
    return domElement;
  });
};
