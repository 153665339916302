import { Divider } from '@mui/material';
import { H3, Text } from '@digitalportal-ui/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSelector } from 'react-redux';
import { Wrapper, SpacedGrid as CardSection, FieldBox } from './styles';
import ConfirmAndPaySummaryProps from './types';
import { TStore } from '../../../redux/store';
import { useGetPaymentDates } from '../../../hooks/useGetPaymentDates';

dayjs.extend(advancedFormat);

function ConfirmAndPaySummary({
  cardData: { monthlyPremium, monthlyTotalContractPrice, annualPremium },
  isMonthlyShown,
  regularPaymentDate,
}: ConfirmAndPaySummaryProps): React.JSX.Element {
  const membersLength = useSelector((state: TStore) => state.quoteState.quote.members).length;
  const coverStartDate = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);
  const { FirstPaymentDate, SubsequentPaymentDay } = useGetPaymentDates(regularPaymentDate);

  return (
    <Wrapper>
      <CardSection>
        <FieldBox>
          <H3 bold>Your Membership</H3>
        </FieldBox>
        {membersLength > 1 ? (
          <FieldBox>
            <Text>{membersLength} people included in this membership</Text>
          </FieldBox>
        ) : (
          <FieldBox>
            <Text>1 person included in this membership</Text>
          </FieldBox>
        )}
      </CardSection>
      <Divider />
      <CardSection>
        <FieldBox>
          <Text display="inline" bold>
            Start date
          </Text>
          <Text align="right" display="inline">
            {coverStartDate === '' ? dayjs(dayjs()).format('Do MMM YYYY') : dayjs(coverStartDate).format('Do MMM YYYY')}
          </Text>
        </FieldBox>
        <FieldBox>
          <Text display="inline" bold>
            Renewal date
          </Text>
          <Text align="right" display="inline">
            {coverStartDate === ''
              ? dayjs(dayjs()).add(1, 'y').format('Do MMM YYYY')
              : dayjs(coverStartDate).add(1, 'y').format('Do MMM YYYY')}
          </Text>
        </FieldBox>
      </CardSection>
      <Divider />
      <CardSection>
        {isMonthlyShown ? (
          <>
            <FieldBox>
              <Text display="inline" bold>
                First payment
              </Text>
              <Text data-testid="first-payment-amount" align="right" display="inline">
                &#163;
                {monthlyPremium.toLocaleString('en-UK', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </FieldBox>
            <FieldBox>
              <Text data-testid="first-payment-date">{FirstPaymentDate()}</Text>
            </FieldBox>
            <FieldBox>
              <Text bold display="inline">
                Monthly payments after this
              </Text>
              <Text data-testid="subsequent-payment-amount" align="right" display="inline">
                &#163;
                {monthlyPremium.toLocaleString('en-UK', {
                  minimumIntegerDigits: 1,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </FieldBox>
            <FieldBox>
              <Text data-testid="subsequent-payment-day">({SubsequentPaymentDay()} of each month)</Text>
            </FieldBox>
          </>
        ) : (
          <>
            <FieldBox>
              <Text display="inline" bold>
                Payment date
              </Text>
            </FieldBox>
            <FieldBox>
              <Text data-testid="first-payment-date">{FirstPaymentDate()}</Text>
            </FieldBox>
          </>
        )}
      </CardSection>
      <Divider />
      <CardSection>
        <FieldBox>
          <Text display="inline" bold>
            Total per year
          </Text>
          <Text data-testid="total-per-year" align="right" display="inline">
            &#163;
            {(!isMonthlyShown ? annualPremium : monthlyTotalContractPrice).toLocaleString('en-UK', {
              minimumIntegerDigits: 1,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </FieldBox>
      </CardSection>
    </Wrapper>
  );
}
export default ConfirmAndPaySummary;
